import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalsService } from "@services/utils/globals.service";
import { UtilsService } from "@services/utils/utils.service";

class DevTraceModel {
	traceGroupUuid: string;
	traceTime: Date;
	userName: string;
	appVersion: string;
	deviceType: string;
	traceText: string;
}

@Injectable()
export class DevTraceService {
	public static traceUuid: string;

	constructor(private http: HttpClient) {
	}

	addTrace(traceText: string, forceSave: boolean = false) {
		if (forceSave === false && !(GlobalsService.companySettings?.saveDevTraces ?? false))
			return;

		try {
			if (!DevTraceService.traceUuid)
				DevTraceService.traceUuid = UtilsService.newGuid();

			const model = new DevTraceModel();
			//model.traceTime = new Date();
			model.traceGroupUuid = DevTraceService.traceUuid;

			if (GlobalsService.userInfo)
				model.userName = GlobalsService.userInfo.userName || 'N/A';
			else
				model.userName = "N/A";
			model.traceGroupUuid
			model.appVersion = GlobalsService.appVersion;
			model.deviceType = "Unknown";
			if (GlobalsService.isAndroid)
				model.deviceType = "Android";
			else if (GlobalsService.isiOS)
				model.deviceType = "iOS";
			model.traceText = traceText;
			this.http.post(`/devTrace/addTrace?trace=${traceText}&forceSave=${forceSave}`, model).toPromise();
		}
		finally { }
	}
}
